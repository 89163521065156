.loading-container {
  height: 100%;
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;

  .spinner {
    flex: 1;
    margin-top: -40px;
  }
}
